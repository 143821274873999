<div class="add_dialog_wrapper">
    <div class="input_wrapper">
        <p>Title</p>
        <input [(ngModel)]="title" type="text">
    </div>
    <div class="input_wrapper">
        <p>Description</p>
        <textarea [(ngModel)]="description" name="" id="" cols="30" rows="10"></textarea>
    </div>
    <div class="btn_wrapper">
        <button class="btn emptyBtn" (click)="closeDialog()">Cancel</button>
        <button class="btn fullBtn" (click)="addNote()">add note</button>
    </div>
    
</div>