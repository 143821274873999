<header>
    <div>
        <svg width="112" height="32" viewBox="0 0 112 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_23_5150)">
                <path
                    d="M33.786 4.092V20.739L45.047 32H61.694C63.954 32 65.786 30.168 65.786 27.908V4.092C65.786 1.832 63.954 0 61.694 0H37.878C35.618 0 33.786 1.832 33.786 4.092Z"
                    fill="#FD5B4F" />
                <path
                    d="M57.732 17.4181V23.5801C57.732 24.5481 56.948 25.3321 55.98 25.3321C55.422 25.3321 54.898 25.0671 54.568 24.6171L46.179 13.2021C45.633 12.4591 44.455 12.8451 44.455 13.7671V25.3311H41.841V8.42009C41.841 7.45209 42.625 6.66809 43.593 6.66809H43.648C44.205 6.66809 44.729 6.93309 45.059 7.38109L53.369 18.6711C53.916 19.4131 55.093 19.0271 55.093 18.1051V11.5371L56.376 13.2821C57.257 14.4811 57.732 15.9301 57.732 17.4181Z"
                    fill="white" />
                <path
                    d="M57.732 7.98798V12.822L55.81 10.211C55.344 9.57798 55.093 8.81298 55.093 8.02698C55.093 7.29798 55.689 6.66498 56.419 6.66798C56.782 6.66998 57.109 6.81698 57.346 7.05498C57.584 7.29298 57.732 7.62298 57.732 7.98798Z"
                    fill="white" />
                <path
                    d="M67.89 25.652C66.557 25.652 65.384 25.354 64.371 24.759C63.358 24.164 62.562 23.342 61.985 22.293C61.407 21.244 61.119 20.036 61.119 18.667C61.119 17.298 61.403 16.09 61.972 15.041C62.541 13.992 63.332 13.17 64.345 12.575C65.358 11.98 66.522 11.682 67.838 11.682C69.154 11.682 70.318 11.98 71.331 12.575C72.344 13.171 73.135 13.993 73.704 15.041C74.273 16.09 74.557 17.299 74.557 18.667C74.557 20.035 74.272 21.245 73.704 22.293C73.135 23.342 72.348 24.164 71.344 24.759C70.34 25.354 69.188 25.652 67.891 25.652H67.89ZM67.89 23.332C68.69 23.332 69.401 23.132 70.023 22.732C70.645 22.332 71.13 21.785 71.476 21.092C71.823 20.399 71.996 19.59 71.996 18.666C71.996 17.742 71.823 16.933 71.476 16.24C71.129 15.547 70.636 15 69.996 14.6C69.356 14.2 68.636 14 67.836 14C67.036 14 66.298 14.2 65.676 14.6C65.054 15 64.565 15.547 64.21 16.24C63.854 16.933 63.677 17.742 63.677 18.666C63.677 19.59 63.854 20.399 64.21 21.092C64.565 21.785 65.063 22.332 65.703 22.732C66.343 23.132 67.071 23.332 67.889 23.332H67.89Z"
                    fill="white" />
                <path
                    d="M76.022 14.187V12.001H84.607V14.187H76.022ZM82.474 25.652C81.141 25.652 80.11 25.31 79.381 24.625C78.652 23.941 78.288 22.959 78.288 21.679V7.841H80.821V21.306C80.821 21.964 80.985 22.466 81.314 22.812C81.643 23.159 82.118 23.332 82.741 23.332C82.918 23.332 83.114 23.297 83.327 23.225C83.54 23.154 83.798 23.012 84.1 22.798L85.06 24.771C84.598 25.073 84.158 25.296 83.74 25.438C83.322 25.58 82.9 25.651 82.473 25.651L82.474 25.652Z"
                    fill="white" />
                <path
                    d="M93.352 25.652C92.054 25.652 90.908 25.354 89.912 24.759C88.916 24.164 88.134 23.342 87.566 22.293C86.997 21.244 86.713 20.036 86.713 18.667C86.713 17.298 86.997 16.09 87.566 15.041C88.135 13.992 88.921 13.17 89.926 12.575C90.93 11.98 92.081 11.682 93.379 11.682C94.588 11.682 95.659 11.989 96.592 12.602C97.525 13.215 98.254 14.086 98.778 15.215C99.302 16.344 99.564 17.673 99.564 19.201H97.031C97.048 18.064 96.911 17.104 96.618 16.321C96.325 15.539 95.898 14.948 95.338 14.548C94.778 14.148 94.107 13.948 93.325 13.948C92.489 13.948 91.769 14.139 91.165 14.521C90.56 14.903 90.094 15.445 89.765 16.147C89.436 16.849 89.272 17.707 89.272 18.72C89.272 19.662 89.459 20.476 89.832 21.16C90.205 21.844 90.712 22.378 91.352 22.76C91.992 23.142 92.721 23.333 93.538 23.333C94.409 23.333 95.133 23.129 95.711 22.72C96.288 22.311 96.746 21.787 97.084 21.147L99.35 22.187C98.994 22.88 98.532 23.489 97.964 24.013C97.395 24.538 96.724 24.942 95.951 25.226C95.178 25.51 94.311 25.653 93.351 25.653L93.352 25.652ZM88.366 19.2L88.393 17.12H98.152V19.2H88.367H88.366Z"
                    fill="white" />
                <path
                    d="M106.843 25.652C105.901 25.652 105.061 25.51 104.323 25.225C103.585 24.941 102.963 24.558 102.457 24.078C101.95 23.598 101.573 23.056 101.324 22.452L103.51 21.492C103.794 22.043 104.221 22.496 104.79 22.852C105.359 23.208 105.99 23.385 106.683 23.385C107.447 23.385 108.078 23.243 108.576 22.958C109.074 22.674 109.323 22.274 109.323 21.758C109.323 21.242 109.136 20.869 108.763 20.585C108.39 20.301 107.847 20.07 107.137 19.892L105.884 19.572C104.64 19.235 103.675 18.728 102.991 18.052C102.306 17.377 101.964 16.612 101.964 15.759C101.964 14.462 102.381 13.457 103.217 12.746C104.052 12.035 105.287 11.679 106.923 11.679C107.723 11.679 108.456 11.795 109.123 12.026C109.79 12.257 110.358 12.586 110.829 13.013C111.3 13.44 111.633 13.946 111.829 14.533L109.696 15.493C109.483 14.96 109.118 14.564 108.603 14.307C108.087 14.05 107.483 13.92 106.79 13.92C106.097 13.92 105.519 14.076 105.11 14.387C104.701 14.698 104.497 15.129 104.497 15.68C104.497 15.982 104.67 16.271 105.017 16.546C105.364 16.822 105.874 17.048 106.55 17.226L107.99 17.573C108.861 17.786 109.581 18.111 110.15 18.546C110.719 18.982 111.145 19.47 111.43 20.012C111.714 20.554 111.857 21.119 111.857 21.705C111.857 22.505 111.639 23.203 111.204 23.798C110.768 24.394 110.173 24.851 109.418 25.171C108.662 25.491 107.805 25.651 106.845 25.651L106.843 25.652Z"
                    fill="white" />
                <path
                    d="M14.292 11.321C13.478 9.89295 12.349 8.78095 10.902 7.98495C9.474 7.17095 7.828 6.76495 5.966 6.76495H0V25.749H5.966C7.828 25.749 9.474 25.342 10.902 24.529C12.348 23.715 13.478 22.595 14.292 21.166C15.124 19.738 15.54 18.101 15.54 16.257C15.54 14.413 15.124 12.75 14.292 11.321ZM11.851 18.833C11.526 19.628 11.074 20.325 10.495 20.921C9.916 21.518 9.239 21.988 8.461 22.331C7.684 22.656 6.852 22.819 5.966 22.819H3.146V9.69295H5.966C6.852 9.69295 7.684 9.86495 8.461 10.208C9.238 10.533 9.916 10.994 10.495 11.591C11.074 12.188 11.526 12.884 11.851 13.679C12.176 14.474 12.339 15.333 12.339 16.255C12.339 17.177 12.176 18.036 11.851 18.831V18.833Z"
                    fill="white" />
                <path
                    d="M30.699 25.748H34.035L26.442 6.76398H23.106L15.54 25.748H18.876L20.695 21.083H28.881L30.7 25.748H30.699ZM21.783 18.29L24.787 10.589L27.79 18.29H21.783Z"
                    fill="white" />
                <path d="M33.786 20.739L45.047 32V24.831C45.047 22.571 43.215 20.739 40.955 20.739H33.786Z"
                    fill="#FD8A81" />
            </g>
            <defs>
                <clipPath id="clip0_23_5150">
                    <rect width="111.856" height="32" fill="white" />
                </clipPath>
            </defs>
        </svg>

    </div>
    <div class="addBtn" (click)="openNoteChange.emit(true)" (mouseenter)="hoveredAddBtn = true"
        (mouseleave)="hoveredAddBtn = false">
        <p>Add note</p>
        <div>
            @if(hoveredAddBtn){
            <svg width="14" height="14" viewBox="0 0 14 14" fill="none">
                <path
                    d="M6 8H1C0.716667 8 0.479167 7.90417 0.2875 7.7125C0.0958333 7.52083 0 7.28333 0 7C0 6.71667 0.0958333 6.47917 0.2875 6.2875C0.479167 6.09583 0.716667 6 1 6H6V1C6 0.716667 6.09583 0.479167 6.2875 0.2875C6.47917 0.0958333 6.71667 0 7 0C7.28333 0 7.52083 0.0958333 7.7125 0.2875C7.90417 0.479167 8 0.716667 8 1V6H13C13.2833 6 13.5208 6.09583 13.7125 6.2875C13.9042 6.47917 14 6.71667 14 7C14 7.28333 13.9042 7.52083 13.7125 7.7125C13.5208 7.90417 13.2833 8 13 8H8V13C8 13.2833 7.90417 13.5208 7.7125 13.7125C7.52083 13.9042 7.28333 14 7 14C6.71667 14 6.47917 13.9042 6.2875 13.7125C6.09583 13.5208 6 13.2833 6 13V8Z"
                    fill="white" />
            </svg>
            }
            @if(!hoveredAddBtn){
            <svg width="14" height="14" viewBox="0 0 14 14" fill="none">
                <path
                    d="M6 8H1C0.716667 8 0.479167 7.90417 0.2875 7.7125C0.0958333 7.52083 0 7.28333 0 7C0 6.71667 0.0958333 6.47917 0.2875 6.2875C0.479167 6.09583 0.716667 6 1 6H6V1C6 0.716667 6.09583 0.479167 6.2875 0.2875C6.47917 0.0958333 6.71667 0 7 0C7.28333 0 7.52083 0.0958333 7.7125 0.2875C7.90417 0.479167 8 0.716667 8 1V6H13C13.2833 6 13.5208 6.09583 13.7125 6.2875C13.9042 6.47917 14 6.71667 14 7C14 7.28333 13.9042 7.52083 13.7125 7.7125C13.5208 7.90417 13.2833 8 13 8H8V13C8 13.2833 7.90417 13.5208 7.7125 13.7125C7.52083 13.9042 7.28333 14 7 14C6.71667 14 6.47917 13.9042 6.2875 13.7125C6.09583 13.5208 6 13.2833 6 13V8Z"
                    fill="#FD5B4F" />
            </svg>
            }
        </div>
    </div>
</header>