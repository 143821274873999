<div class="notes_wrapper">
    <div class="notes_list_button_wrapper">
        <div>
            <button (click)="changeFavFilter('all')" [disabled]="status == 'trash'" class="btn light_btn"
                [ngClass]="{'btn_active': favFilter == 'all'}">
                @if (favFilter == 'all') {
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g id="Filters icons">
                        <mask id="mask0_23_5087" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0"
                            width="24" height="24">
                            <rect id="Bounding box" width="24" height="24" fill="#D9D9D9" />
                        </mask>
                        <g mask="url(#mask0_23_5087)">
                            <path id="note_stack"
                                d="M7.00001 20V8.975C7.00001 8.425 7.20001 7.95833 7.60001 7.575C8.00001 7.19167 8.47501 7 9.02501 7H20C20.55 7 21.0208 7.19583 21.4125 7.5875C21.8042 7.97917 22 8.45 22 9V17L17 22H9.00001C8.45001 22 7.97918 21.8042 7.58751 21.4125C7.19585 21.0208 7.00001 20.55 7.00001 20ZM2.02501 6.25C1.92501 5.7 2.03335 5.20417 2.35001 4.7625C2.66668 4.32083 3.10001 4.05 3.65001 3.95L14.5 2.025C15.05 1.925 15.5458 2.03333 15.9875 2.35C16.4292 2.66667 16.7 3.1 16.8 3.65L17.05 5H15L14.825 4L4.00001 5.925L5.00001 11.575V18.55C4.73335 18.4 4.50418 18.2 4.31251 17.95C4.12085 17.7 4.00001 17.4167 3.95001 17.1L2.02501 6.25ZM9.00001 9V20H16V17C16 16.7167 16.0958 16.4792 16.2875 16.2875C16.4792 16.0958 16.7167 16 17 16H20V9H9.00001Z"
                                fill="#B6C4C9" />
                        </g>
                    </g>
                </svg>
                }
                @else {
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g id="Filters icons">
                        <mask id="mask0_23_5039" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0"
                            width="24" height="24">
                            <rect id="Bounding box" width="24" height="24" fill="#D9D9D9" />
                        </mask>
                        <g mask="url(#mask0_23_5039)">
                            <path id="note_stack"
                                d="M7.00001 20V8.975C7.00001 8.425 7.20001 7.95833 7.60001 7.575C8.00001 7.19167 8.47501 7 9.02501 7H20C20.55 7 21.0208 7.19583 21.4125 7.5875C21.8042 7.97917 22 8.45 22 9V17L17 22H9.00001C8.45001 22 7.97918 21.8042 7.58751 21.4125C7.19585 21.0208 7.00001 20.55 7.00001 20ZM2.02501 6.25C1.92501 5.7 2.03335 5.20417 2.35001 4.7625C2.66668 4.32083 3.10001 4.05 3.65001 3.95L14.5 2.025C15.05 1.925 15.5458 2.03333 15.9875 2.35C16.4292 2.66667 16.7 3.1 16.8 3.65L17.05 5H15L14.825 4L4.00001 5.925L5.00001 11.575V18.55C4.73335 18.4 4.50418 18.2 4.31251 17.95C4.12085 17.7 4.00001 17.4167 3.95001 17.1L2.02501 6.25ZM9.00001 9V20H16V17C16 16.7167 16.0958 16.4792 16.2875 16.2875C16.4792 16.0958 16.7167 16 17 16H20V9H9.00001Z"
                                fill="#6BC1E1" />
                        </g>
                    </g>
                </svg>
                }

                <p>All</p>
            </button>
            <button (click)="changeFavFilter('fav')" [disabled]="status == 'trash'" class="btn light_btn"
                [ngClass]="{'btn_active': favFilter == 'fav'}">

                @if (favFilter == 'fav') {
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <g id="bookmark">
                        <mask id="mask0_103_468" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0"
                            width="24" height="24">
                            <rect id="Bounding box" width="24" height="24" fill="#D9D9D9" />
                        </mask>
                        <g mask="url(#mask0_103_468)">
                            <path id="bookmark_2"
                                d="M5 21V5C5 4.45 5.19583 3.97917 5.5875 3.5875C5.97917 3.19583 6.45 3 7 3H17C17.55 3 18.0208 3.19583 18.4125 3.5875C18.8042 3.97917 19 4.45 19 5V21L12 18L5 21ZM7 17.95L12 15.8L17 17.95V5H7V17.95Z"
                                fill="white" />
                        </g>
                    </g>
                </svg>
                }
                @else {
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g id="Property 1=bookmark">
                        <mask id="mask0_71_770" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0"
                            width="24" height="24">
                            <rect id="Bounding box" width="24" height="24" fill="#D9D9D9" />
                        </mask>
                        <g mask="url(#mask0_71_770)">
                            <path id="bookmark"
                                d="M5 21V5C5 4.45 5.19583 3.97917 5.5875 3.5875C5.97917 3.19583 6.45 3 7 3H17C17.55 3 18.0208 3.19583 18.4125 3.5875C18.8042 3.97917 19 4.45 19 5V21L12 18L5 21ZM7 17.95L12 15.8L17 17.95V5H7V17.95Z"
                                fill="#6BC1E1" />
                        </g>
                    </g>
                </svg>
                }



                <p>Favorites</p>
            </button>
        </div>
        <div>
            <button (click)="changeTrashStatus()" class="white_btn_round "
                [ngClass]="{'back_arrow_active': status == 'trash', 'back_arrow_inactive': status != 'trash'}">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g id="arrow_back">
                        <mask id="mask0_103_413" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0"
                            width="24" height="24">
                            <rect id="Bounding box" width="24" height="24" fill="#D9D9D9" />
                        </mask>
                        <g mask="url(#mask0_103_413)">
                            <path id="arrow_back_2"
                                d="M7.8248 13L12.7248 17.9C12.9248 18.1 13.0206 18.3333 13.0123 18.6C13.004 18.8667 12.8998 19.1 12.6998 19.3C12.4998 19.4833 12.2665 19.5792 11.9998 19.5875C11.7331 19.5958 11.4998 19.5 11.2998 19.3L4.6998 12.7C4.5998 12.6 4.52897 12.4917 4.4873 12.375C4.44564 12.2583 4.4248 12.1333 4.4248 12C4.4248 11.8667 4.44564 11.7417 4.4873 11.625C4.52897 11.5083 4.5998 11.4 4.6998 11.3L11.2998 4.7C11.4831 4.51667 11.7123 4.425 11.9873 4.425C12.2623 4.425 12.4998 4.51667 12.6998 4.7C12.8998 4.9 12.9998 5.1375 12.9998 5.4125C12.9998 5.6875 12.8998 5.925 12.6998 6.125L7.8248 11H18.9998C19.2831 11 19.5206 11.0958 19.7123 11.2875C19.904 11.4792 19.9998 11.7167 19.9998 12C19.9998 12.2833 19.904 12.5208 19.7123 12.7125C19.5206 12.9042 19.2831 13 18.9998 13H7.8248Z"
                                fill="white" />
                        </g>
                    </g>
                </svg>
            </button>

            <button (click)="changeTrashStatus()" class="btn trash_btn"
                [ngClass]="{'trash_btn_active': status == 'trash'}">
                @if (status != 'trash') {
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <g id="Filters icons">
                        <mask id="mask0_77_918" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0"
                            width="24" height="24">
                            <rect id="Bounding box" width="24" height="24" fill="#D9D9D9" />
                        </mask>
                        <g mask="url(#mask0_77_918)">
                            <path id="delete"
                                d="M7 21C6.45 21 5.97917 20.8042 5.5875 20.4125C5.19583 20.0208 5 19.55 5 19V6C4.71667 6 4.47917 5.90417 4.2875 5.7125C4.09583 5.52083 4 5.28333 4 5C4 4.71667 4.09583 4.47917 4.2875 4.2875C4.47917 4.09583 4.71667 4 5 4H9C9 3.71667 9.09583 3.47917 9.2875 3.2875C9.47917 3.09583 9.71667 3 10 3H14C14.2833 3 14.5208 3.09583 14.7125 3.2875C14.9042 3.47917 15 3.71667 15 4H19C19.2833 4 19.5208 4.09583 19.7125 4.2875C19.9042 4.47917 20 4.71667 20 5C20 5.28333 19.9042 5.52083 19.7125 5.7125C19.5208 5.90417 19.2833 6 19 6V19C19 19.55 18.8042 20.0208 18.4125 20.4125C18.0208 20.8042 17.55 21 17 21H7ZM17 6H7V19H17V6ZM9 17H11V8H9V17ZM13 17H15V8H13V17Z"
                                fill="#6BC1E1" />
                        </g>
                    </g>
                </svg>
                }
                @else {
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <g id="Filters icons">
                        <mask id="mask0_77_921" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0"
                            width="24" height="24">
                            <rect id="Bounding box" width="24" height="24" fill="#D9D9D9" />
                        </mask>
                        <g mask="url(#mask0_77_921)">
                            <path id="delete"
                                d="M7 21C6.45 21 5.97917 20.8042 5.5875 20.4125C5.19583 20.0208 5 19.55 5 19V6C4.71667 6 4.47917 5.90417 4.2875 5.7125C4.09583 5.52083 4 5.28333 4 5C4 4.71667 4.09583 4.47917 4.2875 4.2875C4.47917 4.09583 4.71667 4 5 4H9C9 3.71667 9.09583 3.47917 9.2875 3.2875C9.47917 3.09583 9.71667 3 10 3H14C14.2833 3 14.5208 3.09583 14.7125 3.2875C14.9042 3.47917 15 3.71667 15 4H19C19.2833 4 19.5208 4.09583 19.7125 4.2875C19.9042 4.47917 20 4.71667 20 5C20 5.28333 19.9042 5.52083 19.7125 5.7125C19.5208 5.90417 19.2833 6 19 6V19C19 19.55 18.8042 20.0208 18.4125 20.4125C18.0208 20.8042 17.55 21 17 21H7ZM17 6H7V19H17V6ZM9 17H11V8H9V17ZM13 17H15V8H13V17Z"
                                fill="white" />
                        </g>
                    </g>
                </svg>
                }



                <p>Trash</p>
            </button>
        </div>
    </div>
    <div>

        <ul>
            @for (item of (this.noteService.items$ | async); track item) {
            <li>
                {{ item['title'] }}
            </li>
            }
        </ul>

        <!-- @for (note of noteList; track note) {
            <app-note [note]="note"></app-note>
          } -->
    </div>
</div>