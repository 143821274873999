import { ApplicationConfig } from '@angular/core';
import { provideRouter } from '@angular/router';

import { routes } from './app.routes';
import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { getFirestore, provideFirestore } from '@angular/fire/firestore';

export const appConfig: ApplicationConfig = {
  providers: [provideRouter(routes), provideFirebaseApp(() => initializeApp({"projectId":"danotes-af5ae","appId":"1:22830055321:web:04c3533fd00732a6d91d08","storageBucket":"danotes-af5ae.appspot.com","apiKey":"AIzaSyB22GaKmCvo6r51nWcL5ufdBbN8JJzAZTI","authDomain":"danotes-af5ae.firebaseapp.com","messagingSenderId":"22830055321"})), provideFirestore(() => getFirestore())]
};
